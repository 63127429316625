import React, { useState } from 'react'

interface GridImagesProps {
  images: string[];
}

const GridImages = ({ images }: GridImagesProps) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const isTouchDevice = window.matchMedia('(hover: none)').matches;

  // const images = [
  //   '/gridimages/1.png',
  //   '/gridimages/2.png',
  //   '/gridimages/3.png',
  //   '/gridimages/4.png',
  //   '/gridimages/5.png',
  //   '/gridimages/6.png',
  //   '/gridimages/7.png',
  //   '/gridimages/8.png',
  // ]

  const openPopup = (image: React.SetStateAction<string | null>) => {
    setSelectedImage(image);
    setIsPopupOpen(true);
  }

  const closePopup = () => {
    setIsPopupOpen(false);
  }

  const nextImage = () => {
    if (selectedImage !== null) {
      const currentIndex = images.indexOf(selectedImage);
      const nextIndex = (currentIndex + 1) % images.length;
      setSelectedImage(images[nextIndex]);
    }
  }

  const prevImage = () => {
    if (selectedImage !== null) {
      const currentIndex = images.indexOf(selectedImage);
      const prevIndex = (currentIndex - 1 + images.length) % images.length;
      setSelectedImage(images[prevIndex]);
    }
  }

  return (
    <div className="cursor-pointer w-full max-h-[30rem] lg:max-h-[50rem] grid grid-cols-6 grid-rows-3 gap-0">
      <div onClick={isTouchDevice ? () => { } : () => openPopup(images[0])} className="col-start-1 col-span-3 row-start-1 row-span-1"><img className="object-cover w-full h-full" src={images[0]} alt="grid1" /></div>
      <div onClick={isTouchDevice ? () => { } : () => openPopup(images[1])} className="col-start-4 col-span-2 row-start-1 row-span-1"><img className="object-cover w-full h-full" src={images[1]} alt="grid2" /></div>
      <div onClick={isTouchDevice ? () => { } : () => openPopup(images[2])} className="col-start-6 col-span-1 row-start-1 row-span-1"><img className="object-cover w-full h-full" src={images[2]} alt="grid3" /></div>
      <div onClick={isTouchDevice ? () => { } : () => openPopup(images[3])} className="col-start-1 col-span-2 row-start-2 row-span-1"><img className="object-cover w-full h-full" src={images[3]} alt="grid4" /></div>
      <div onClick={isTouchDevice ? () => { } : () => openPopup(images[4])} className="col-start-3 col-span-2 row-start-2 row-span-2"><img className="object-cover w-full h-full" src={images[4]} alt="grid5" /></div>
      <div onClick={isTouchDevice ? () => { } : () => openPopup(images[5])} className="col-start-1 col-span-2 row-start-3 row-span-1"><img className="object-cover w-full h-full" src={images[5]} alt="grid6" /></div>
      <div onClick={isTouchDevice ? () => { } : () => openPopup(images[6])} className="col-start-5 col-span-2 row-start-2 row-span-1"><img className="object-cover w-full h-full" src={images[6]} alt="grid7" /></div>
      <div onClick={isTouchDevice ? () => { } : () => openPopup(images[7])} className="col-start-5 col-span-2 row-start-3 row-span-1"><img className="object-cover w-full h-full" src={images[7]} alt="grid8" /></div>
      {isPopupOpen && (
        <div className="z-50 fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-80 text-white">
          <button onClick={prevImage} className='absolute top-1/2 -translate-y-1/2 left-4 text-6xl bg-black rounded-full px-6 py-2'>{'<'}</button>
          <img className="object-cover w-auto h-fit" src={selectedImage || ''} alt="selected" />
          <button onClick={nextImage} className='absolute top-1/2 -translate-y-1/2 right-4 text-6xl bg-black rounded-full px-6 py-2'>{'>'}</button>
          <button className='text-4xl absolute bottom-2 left-1/2 -translate-x-1/2 bg-black rounded-full px-4 py-2' onClick={closePopup}>Close photos x</button>
        </div>
      )}
    </div>
  )
}

export default GridImages