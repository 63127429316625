import React from 'react'

const Seventh = () => {
  return (
    <section className='flex flex-col justify-around p-12 min-h-screen w-full bg-mpa-light-black text-white'>
      <p className='max-w-4xl text-lg md:text-2xl lg:text-3xl'>
        Definiamo con il cliente l’obiettivo, il posizionamento da raggiungere con il suo brand o prodotto
        e creiamo in maniera strategica la comunicazione su tutti i touch point per raggiungere il risultato.
      </p>
      <p className='max-w-4xl text-lg md:text-2xl lg:text-3xl'>
        <span className='text-mpa-yellow'>Creatività libera</span> all’interno di una <span className='text-mpa-yellow'>strategia rigorosa</span>.
        Sembra complicato… infatti lo è. Se non lo fosse non saremmo qui.
      </p>
      <div className='w-full flex flex-col gap-8'>
        <p className='w-full text-3xl md:text-5xl lg:text-7xl'>
          <span className="text-white">Strategie di comunicazione & branding</span>&nbsp;
          <span className="text-mpa-yellow">Consulenza creativa</span>&nbsp;
          <span className="text-mpa-gray">Branding & design</span>&nbsp;
          <span className="text-white">Brand Content</span>&nbsp;
          <span className="text-mpa-yellow">Advertising campaign</span>&nbsp;
          <span className="text-mpa-gray">Below the line</span>&nbsp;
          <span className="text-white">Stand per fiere & espositori speciali</span>&nbsp;
          <span className="text-mpa-yellow">Siti web e pagine dedicate</span>&nbsp;
          <span className="text-mpa-gray">Social media managment</span>&nbsp;
          <span className="text-white">News letter</span>&nbsp;
        </p>
        <button
          className='w-fit border-2 border-mpa-yellow px-6 py-2 uppercase text-mpa-yellow text-2xl md:text-4xl lg:text-6xl'
          onClick={() => document.getElementById('contacts')?.scrollIntoView({ behavior: 'smooth' })}
        >
          Contattaci
        </button>
      </div>
    </section>
  )
}

export default Seventh