import React from "react";

const Navbar = ({ isMenuOpen, setIsMenuOpen }: { isMenuOpen: boolean; setIsMenuOpen: Function }) => {
  return (
    <div className="fixed top-0 right-0 flex gap-4 justify-center items-center bg-black bg-opacity-50 z-50 w-24 h-12 cursor-pointer" onClick={() => setIsMenuOpen(!isMenuOpen)} style={{ clipPath: "polygon(20% 0%, 100% 0, 100% 100%, 0% 100%)" }}>
      <div className="relative w-full h-full">
      {/* <span className='text-mpa-yellow text-3xl'>it</span> */}
      {!isMenuOpen && (
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <div style={{ width: "35px", height: "3px", backgroundColor: "#FFC600", marginBottom: "6px" }}></div>
          <div style={{ width: "35px", height: "3px", backgroundColor: "#FFC600", marginBottom: "6px" }}></div>
          <div style={{ width: "35px", height: "3px", backgroundColor: "#FFC600" }}></div>
        </div>
      )}
      {isMenuOpen && (
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" style={{ width: "35px", height: "3px", backgroundColor: "#FFC600", marginBottom: "6px", transform: "rotate(45deg)" }}></div>
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" style={{ width: "35px", height: "3px", backgroundColor: "#FFC600", marginBottom: "6px", transform: "rotate(-45deg)" }}></div>
        </div>
      )}
      </div>
    </div>
  );
};

export default Navbar;
