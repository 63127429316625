import React from 'react'

const Second = () => {
  return (
    <section id="events" className='relative w-full flex flex-col items-start justify-start h-screen bg-center bg-cover bg-no-repeat px-6 py-32 md:px-28 md:py-28 lg:px-48  lg:py-48' style={{ backgroundImage: `url(/images/2.jpg)`, clipPath: 'polygon(0 0, 50% 0, 100% 35%, 100% 100%, 50% 100%, 0 73%)' }}>
       <h3 className='text-5xl md:text-6xl lg:text-9xl text-mpa-yellow'>Eventi</h3>
      <div className='flex flex-col mb-2'>
        <span className='text-mpa-yellow uppercase'>COMUNICAZIONE & BRANDING</span>
        <span className='text-mpa-yellow uppercase'>VIDEO, AUDIO & PHOTO PRODuCTION</span>
        {/* <span className='text-mpa-yellow uppercase'>DIGITAL & innovation technology</span> */}
      </div>
     
      <p className=' text-xl md:text-3xl lg:text-5xl text-white'>L&apos;Idea che cambia <br />
        la tua idea di evento.
      </p>
    </section>
  )
}

export default Second