import React from 'react'

const Eight = () => {
  return (
    <section id="video-audio-photo" className='relative w-full flex flex-col items-start justify-start h-screen bg-center bg-cover bg-no-repeat px-6 py-32 md:px-28 md:py-28 lg:px-48  lg:py-48' style={{ backgroundImage: `url(/images/4.png)`, clipPath: 'polygon(0 0, 50% 0, 100% 35%, 100% 100%, 50% 100%, 0 73%)' }}>
      <div className='flex flex-col'>
        <span className='text-mpa-yellow uppercase'>Eventi</span>
        <span className='text-mpa-yellow uppercase'>COMUNICAZIONE & BRANDING</span>
      </div>
      <h3 className='text-5xl md:text-6xl lg:text-8xl max-w-xs md:max-w-xl text-mpa-yellow'>VIDEO, AUDIO & <br /> PHOTO PRODuCTION</h3>
      <p className=' text-xl md:text-3xl lg:text-5xl text-white'>
        Il crafting a regola d&apos;arte.
      </p>
    </section>
  )
}

export default Eight