import React from 'react'

const Sixth = () => {
  return (
    <section className='flex flex-col justify-between relative w-full h-screen bg-center bg-cover bg-no-repeat bg-black p-28 px-24 py-56 sm:px-48 sm:py-48' style={{ backgroundImage: ``, clipPath: 'polygon(100% 0, 100% 73%, 50% 100%, 0 100%, 0 35%, 50% 0)' }}>
      <div className='w-full flex justify-end items-end'>
        <p className='max-w-3xl text-white text-lg md:text-2xl lg:text-4xl'>
          Creiamo relazioni tra marchi e persone attraverso <span className='text-mpa-yellow'>idee, comunicazione e tecnologia</span>.
          Crediamo nella forza di idee e strategie per rendere più forte questa connessione. Lavoriamo su tutti i punti di contatto e in tutto il mondo.
        </p>
      </div>
      <div className='w-full flex justify-start items-start'>
        <p className='max-w-3xl text-white text-sm md:text-2xl lg:text-4xl'>
          <span className='text-mpa-yellow'>Comunicare bene è essere interessanti nell’immediato,
          Ma è anche essere sempre coerenti per farsi ricordare e riconoscere.
          servono</span> creatività, <span className='text-mpa-yellow'>esecuzione impeccabile e soprattutto</span> strategia.
        </p>
      </div>
    </section>
  )
}

export default Sixth