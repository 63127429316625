"use client"
import React from 'react'

const Contacs = () => {
  return (
    <section id="contacts" className='bg-mpa-light-black flex flex-wrap gap-24 lg:gap-8 justify-start lg:justify-around items-center px-8 lg:px-2 py-12'>
      <div className='flex flex-col gap-4'>
        <p className='text-5xl md:text-7xl lg:text-9xl text-mpa-yellow'>Contattaci</p>
        <div className='my-4 flex flex-col gap-2'>
          {/* <span className='text-2xl lg:text-3xl text-mpa-yellow'>Hai un&apos;idea? scrivici a</span> */}
          {/* <span className='text-2xl lg:text-3xl text-mpa-yellow'>Per ogni esigenza contattaci a</span> */}
          <a href='mailto:idea@mixerpa.com' className='text-white text-xl lg:text-2xl'>
            idea@mixerpa.com
          </a>
        </div>
        {/* <div className='my-4 flex flex-col gap-2'>
          <span className='text-2xl lg:text-3xl text-mpa-yellow'>Per ogni altra esigenza scrivi a</span>
          <a href='mailto:amministrazione@mixerpa.com' className='text-white text-xl lg:text-2xl'>
            amministrazione@mixerpa.com
          </a>
        </div> */}
      </div>
      <div className='flex flex-col justify-start items-start text-white text-xl lg:text-3xl'>
        <p className='text-mpa-yellow mb-2'>mixer prod.action srl</p>
        <p className='text-white'>P.I. 01629030196</p>
        <p className='text-white'>Via XX Settembre 18,</p>
        <p className='text-white'>26013 Crema (CR), Italy</p>
        <p className='text-white'>Phone +39 0373 86406</p>
        <div className='flex gap-4 justify-start items-center mt-2'>
          <img className='w-6 h-6' src="/images/social/in.png" alt="LinkedIn" onClick={() => window.open('https://www.linkedin.com/company/mixerprod-action/', '_blank')} />
          <img className='w-6 h-6' src="/images/social/ig.png" alt="Instagram" onClick={() => window.open('https://www.instagram.com/mixer_prod.action?igsh=djQ5cnFtazBocnp5', '_blank')} />
          <img className='w-auto h-6' src="/images/social/fb.png" alt="Facebook" onClick={() => window.open('https://www.facebook.com/mixerpa', '_blank')} />
        </div>
      </div>
    </section>
  )
}

export default Contacs