import React from 'react'

const Nineth = () => {
  return (
    <section className='flex flex-col justify-between relative w-full h-screen bg-center bg-cover bg-no-repeat bg-black p-28 px-24 py-56 sm:px-48 sm:py-48' style={{ backgroundImage: ``, clipPath: 'polygon(100% 0, 100% 73%, 50% 100%, 0 100%, 0 35%, 50% 0)' }}>
      <div className='w-full flex justify-end items-end'>
        <p className='max-w-3xl text-white text-lg md:text-2xl lg:text-4xl'>
          L’altra metà del cielo. Abbiamo l’idea: è ora di realizzarla <span className='text-mpa-yellow'>come si deve</span>.
        </p>
      </div>
      <div className='w-full flex justify-start items-start'>
        <p className='max-w-3xl text-white text-sm md:text-2xl lg:text-4xl'>
         <span className='text-mpa-yellow'>Produciamo e realizziamo tutti i contenuti in-house, grazie ai nostri video artist, registi e fotografi interni.
          Un bel vantaggio per chi ama la</span> qualità <span className='text-mpa-yellow'>ma anche la</span> velocità.
        </p>
      </div>
    </section>
  )
}

export default Nineth