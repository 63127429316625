const Menu = ({ isMenuOpen, setIsMenuOpen }: { isMenuOpen: boolean; setIsMenuOpen: Function }) => {
  return (
    <div className={`flex justify-center items-center bg-mpa-light-black w-full h-screen fixed top-0 left-0 z-10 transition-all duration-100 scale-y-0 ${isMenuOpen ? "scale-y-100" : ""}`}>
      <div className="flex flex-col gap-6 sm:gap-10">
        <a href="#events" className="text-2xl md:text-4xl lg:text-6xl text-mpa-yellow hover:bg-mpa-yellow hover:text-mpa-light-black py-2 px-4" onClick={() => setIsMenuOpen(false)}>
          Eventi
        </a>
        <a href="#comunication-branding" className="text-2xl md:text-4xl lg:text-6xl text-mpa-yellow hover:bg-mpa-yellow hover:text-mpa-light-black py-2 px-4" onClick={() => setIsMenuOpen(false)}>
          Comunicazione & branding
        </a>
        <a href="#video-audio-photo" className="text-2xl md:text-4xl lg:text-6xl text-mpa-yellow hover:bg-mpa-yellow hover:text-mpa-light-black py-2 px-4" onClick={() => setIsMenuOpen(false)}>
          Video, audio & photo production
        </a>
        {/* <a href="#work-with-us" className="text-2xl md:text-4xl lg:text-6xl text-mpa-yellow hover:bg-mpa-yellow hover:text-mpa-light-black py-2 px-4" onClick={() => setIsMenuOpen(false)}>
          Lavora con noi
        </a> */}
        <a href="#contacts" className="text-2xl md:text-4xl lg:text-6xl text-mpa-yellow hover:bg-mpa-yellow hover:text-mpa-light-black py-2 px-4" onClick={() => setIsMenuOpen(false)}>
          Contatti
        </a>
      </div>
    </div>
  );
};
export default Menu;
