import React from 'react'

const Third = () => {
  return (
    <section className='flex flex-col justify-between relative w-full h-screen bg-center bg-cover bg-no-repeat bg-black p-28 px-24 py-56 sm:px-48 sm:py-48' style={{ backgroundImage: ``, clipPath: 'polygon(100% 0, 100% 73%, 50% 100%, 0 100%, 0 35%, 50% 0)' }}>
      <div className='w-full flex justify-end items-end'>
      <p className='max-w-3xl text-white text-lg md:text-2xl lg:text-4xl'>
      Tutto inizia da qui: organizziamo, ideiamo e produciamo eventi di ogni dimensione, con migliaia di partecipanti o con poche e selezionate persone. <span className='text-mpa-yellow'>In person, digitali o ibridi</span>.
      </p>
      </div>
      <div className='w-full flex justify-start items-start'>
        <p className='max-w-3xl text-white text-sm md:text-2xl lg:text-4xl'>
        <span className='text-mpa-yellow'> Il nostro plus è creare e coordinare direttamente tutte le fasi del progetto: il concept creativo e le sue declinazioni, il design, la produzione audiovisiva e di contenuti, la parte tecnico/produttiva, la logistica, il marketing e la comunicazione, diventando per Voi l’unico referente e lasciandovi liberi di
        </span>
          &nbsp;godervi il successo del vostro Evento.
        </p>
      </div>
    </section>
  )
}

export default Third