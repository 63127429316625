import React from 'react'

const Fourth = () => {
  return (
    <section className='flex flex-col justify-around p-12 min-h-screen w-full bg-mpa-light-black text-white'>
      <p className='max-w-4xl text-lg md:text-2xl lg:text-3xl'>Siamo sul mercato da anni, strutturati e solidi.
        Grazie al nostro staff composto da professionisti del settore e al nostro know-how, riusciamo a <span className='text-mpa-yellow'>superare sempre le aspettative</span> dei nostri Clienti.</p>
      <div className='w-full flex flex-col gap-8'>
        <p className='w-full text-3xl md:text-5xl lg:text-7xl'>Corporate meeting <span className='text-mpa-yellow'>Learning events</span> <span className='text-mpa-gray'>Celebrazioni & awards</span> Webinar <span className='text-mpa-yellow'>Direzione & Creatività</span> <span className='text-mpa-gray'>Intrattenimento</span> Coinvolgimento del pubblico <span className='text-mpa-yellow'>Gadgets</span> <span className='text-mpa-gray'>Materiale da distribuire ai tuoi ospiti</span> Organizzazione a 360° </p>
        <button
          className='w-fit border-2 border-mpa-yellow px-6 py-2 uppercase text-mpa-yellow text-2xl md:text-4xl lg:text-6xl'
          onClick={() => document.getElementById('contacts')?.scrollIntoView({ behavior: 'smooth' })}
        >
          Contattaci
        </button>
      </div>
    </section>
  )
}

export default Fourth