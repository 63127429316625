import React, { useState } from 'react';
import './App.css';
import Contacts from './components/contacts';
import GridImages from './components/gridimages';
import Menu from './components/menu';
import Navbar from './components/navbar';
import Eight from './components/sections/eight';
import Fifth from './components/sections/fifth';
import Fourth from './components/sections/fourth';
import Header from './components/sections/header';
import Nineth from './components/sections/nineth';
import Second from './components/sections/second';
import Seventh from './components/sections/seventh';
import Sixth from './components/sections/sixth';
import Third from './components/sections/third';
import WorkWithUs from './components/sections/workwithus';
import WordCloud from './components/wordcloud';

function App() {
  let firstGridImages = ["/gridimages/1.jpeg", "/gridimages/2.jpg", "/gridimages/3.jpg", "/gridimages/4.jpg", "/gridimages/5.jpg", "/gridimages/6.jpg", "/gridimages/7.jpg", "/gridimages/8.jpg"];
  let secondGridImages = ["/gridimages2/1.jpg", "/gridimages2/2.jpg", "/gridimages2/3.jpg", "/gridimages2/4.jpg", "/gridimages2/5.jpg", "/gridimages2/6.jpg", "/gridimages2/7.jpg", "/gridimages2/8.jpg"];
  let thirdGridImages = ["/gridimages3/1.jpg", "/gridimages3/2.jpg", "/gridimages3/3.jpg", "/gridimages3/4.jpg", "/gridimages3/5.jpg", "/gridimages3/6.jpg", "/gridimages3/7.jpg", "/gridimages3/8.jpg"];
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <main className="bg-mpa-yellow overflow-x-hidden leading-tight">
      <Menu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
      <Navbar isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
      <Header />
      <Second />
      <Third />
      <GridImages images={firstGridImages} />
      <section className="relative flex justify-center items-center w-full h-screen bg-black">
        <p className="absolute top-6 left-6 text-white text-2xl lg:text-3xl max-w-72">
          <span className="text-mpa-yellow">Lavoriamo sia a livello</span> internazionale che locale, <span className="text-mpa-yellow">sul nostro territorio</span>.
        </p>
        {/* <WordCloud /> */}
        <div className='w-[500px] lg:w-[900px] h-[500px] lg:h-[900px] relative'>
          <img src="/images/word-cloud.png" alt="" className='absolute top-0 left-0 w-full h-full object-contain' />
        </div>
      </section>
      <Fourth />
      <Fifth />
      <Sixth />
      <Seventh />
      <GridImages images={secondGridImages} />
      <Eight />
      <Nineth />
      <GridImages images={thirdGridImages} />
      {/* <WorkWithUs /> */}
      <Contacts />
    </main>
  );
}

export default App;