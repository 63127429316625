import React, { useState } from 'react'

const Header = () => {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  
  return (
    <>
    <section className='relative flex justify-center items-center w-full h-screen bg-center bg-cover bg-no-repeat' style={{ clipPath: 'polygon(100% 0, 100% 73%, 50% 100%, 0 100%, 0 35%, 50% 0)' }}>
      <video autoPlay loop muted style={{ position: 'absolute', width: '100%', left: '50%', top: '50%', height: '100%', objectFit: 'cover', transform: 'translate(-50%, -50%)', zIndex: '-1'}}>
        <source src="/video/sample.mp4" type="video/mp4" />
      </video>
      <div style={{ position: 'absolute', background: 'rgba(0, 0, 0, 0.75)', width: '100%', height: '100%', zIndex: '-1' }}></div>
      <div className='w-full max-w-8xl h-fit flex flex-row flex-wrap justify-center items-start gap-12 p-12 md:p-6'>
        <img src="/images/logo-header.png" className=' w-52 md:w-72 lg:w-96 h-auto'/>
        <div className='flex flex-col justify-start items-start gap-12'>
        <h2 className='uppercase text-white text-2xl md:text-4xl lg:text-6xl max-w-[50rem]'>
          Siamo una company che, attraverso eventi, comunicazione e tecnologia,
          crea relazioni tra <span className='text-mpa-yellow'>brand e persone</span>.
        </h2>
        <button className='border-2 border-mpa-yellow px-4 py-2 uppercase text-mpa-yellow text-2xl md:text-4xl lg:text-6xl' onClick={handleOpenModal}>
        Guarda Il Reel
      </button>
        </div>
      </div>
      
    </section>
    {showModal && (
        <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 99 }}>
          <div style={{ position: 'relative', width: '80%', height: '80%' }}>
            {/* <iframe src="https://player.vimeo.com/video/905575525" style={{ width: '100%', height: '100%' }} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe> */}
            <iframe src="https://player.vimeo.com/video/905550288?h=fd9895edb7&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" style={{ width: '100%', height: '100%' }}  frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" title="Video Corporate Mixer 2024 240123  Per Web"></iframe>
          </div>
          <button className='text-xl lg:text-4xl absolute bottom-2 left-1/2 -translate-x-1/2 bg-black rounded-full px-4 py-2 text-white' onClick={handleCloseModal}>Close video X</button>
        </div>
      )}
    </>
  )
}

export default Header